import { COMMUNICATION_TYPE } from '../../../../misc/commons'
import noticeWebform from '../../../share/centralized-notice-webform'
import _ from 'lodash'
export default {
    components: {
        noticeWebform,
    },
    data() {
        return {
            organizationId: null,
            providerCommunicationId: null,
            communicationId: null,
            communicationType: COMMUNICATION_TYPE.RECALL
        }
    },
    created() {
        if (this.$route && this.$route.query.o && this.$route.query.r && this.$route.query.pr) {
            this.organizationId = Number(this.$route.query.o)
            this.communicationId = this.$route.query.r
            this.providerCommunicationId = Number(this.$route.query.pr)
        }
    },
}
